import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  color: #212121;
  #home {
    @media (max-width:450px) {
      display: none;
    }
    padding-left: 2em;
  }
  #link {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 2em;
  }
`;

const StyledItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0 0.5em;
  align-self: flex-end;
  .header-selected {
    box-shadow: inset 0 -8px 0 rgba(0, 231, 255, 1);
  }
  h3:hover {
    box-shadow: inset 0 -8px 0 rgba(0, 231, 255, 1);
  }
`;

const HeaderItem = ({ title, edge, selected }) => {
  const link = title === 'bilegjargal.me' ? '' : title;
  return (
    <StyledItem>
      <Link to={`/${link}`} style={{ textDecoration: 'none', color: '#212121' }}>
        {
          title !== 'bilegjargal.me' ? (
            <div className={selected ? 'header-selected' : 'header'}>
              <h3 className={edge ? 'header-edge' : ''}>{title}</h3>
            </div>
          ) : (
            <h3>{title}</h3>
          )
        }
      </Link>
    </StyledItem>
  )
}

const Header = () => {
  const page = useParams().page || 'about';
  return (
    <StyledHeader>
      <div id="home">
        <HeaderItem title='bilegjargal.me' selected={true} edge />
      </div>
      <div id="link">
        <HeaderItem title='about' selected={page === 'about'} />
        <HeaderItem title='contact' selected={page === 'contact'} edge={true} />
      </div>
    </StyledHeader>
  )
}

export default Header;