import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import profileImage from "../assets/profile-image.webp";

const StyledAbout = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 8rem - 32px);
  @media (max-width: 450px) {
    height: 100%;
  }

  .about {
    max-height: 100%;
  }

  .aboutContainer {
    max-width: 80rem;
    @media (max-width: 958px) {
      justify-content: center;
      padding: 0 6rem 6rem;
    }
    a {
      text-decoration: none;
      color: #212121;
    }
    #contactpage {
      box-shadow: inset 0 -8px 0 #f9bc0380;
    }
    #company {
      box-shadow: inset 0 -8px 0 #68aad8bf;
      font-weight: bold;
    }
  }
  .textContainer {
    #greeting {
      font-weight: bold;
    }
  }

  .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 20rem;
    height: auto;
    border-radius: 99em;
    border: 0 solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
  }

  p {
    line-height: 1.6;
  }

  .guitar {
    display: inline-block;
    animation: shake-animation 4.209s ease infinite;
    transform-origin: 50% 50%;
  }

  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 0);
    }
    3.57143% {
      transform: translate(0, 0);
    }
    5.35714% {
      transform: translate(5px, 0);
    }
    7.14286% {
      transform: translate(0, 0);
    }
    8.92857% {
      transform: translate(5px, 0);
    }
    10.71429% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;
const About = () => {
  return (
    <StyledAbout>
      <div className="about">
        <Grid container className="aboutContainer">
          <Grid item sm={12} md={4} className="imgContainer">
            <img alt="one cool guy is smiling" src={profileImage} />
          </Grid>
          <Grid item sm={12} md={8} className="textContainer">
            <p>
              <span id="greeting">Hi, I'm Jargal</span>, a software engineer.
              <br />
              <br />
              Currently, I am building a content creation platform using
              React.js, TypeScript, Node.js and Java{" "}
              <a href="https://www.arcxp.com" id="company">
                @Arc XP
              </a>{" "}
              <br />
              <br />
              When I am not coding, I will probably be running or playing some
              video games or teaching myself to play{" "}
              <div className="guitar">
                <a
                  href="https://music.youtube.com/playlist?list=OLAK5uy_nMoBdm6HssFJ37J4_D4aDwQCkrMpnd_yI&feature=share"
                  target="_blank"
                  rel="noreferrer"
                  alt="Fav album"
                >
                  guitar 🎸
                </a>
              </div>
              <br />
              <br />
              If you tend to maintain the code base clean and well document, we
              can become friends so easily. So feel free to{" "}
              <a href="/contact" id="contactpage">
                reach out to me
              </a>{" "}
              ^_^
            </p>
          </Grid>
        </Grid>
      </div>
    </StyledAbout>
  );
};

export default About;
