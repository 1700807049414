import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
const StyledContact = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 8rem - 32px);

  .contactContainer {
    display: flex;
    flex-direction:column;
    max-width: 50rem;
    @media (max-width: 958px) {
      padding: 0 6rem 6rem;
    }
    
    input[type=text],textarea {
      border: none;
      padding: 6px 16px;
      box-shadow: inset 0px 0px 6px -3px #212121;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 24px;
      width: fill-available;
    }

    .submit {
      width: 100%;
      padding: 6px 12px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      color: #212121;
      box-shadow: 2px 2px #21212150;
      text-transform: uppercase;
    }
  }

  a {
    text-decoration: none;
    color: #212121;
  }
  #email {
    box-shadow: inset 0 -8px 0 #f9bc0380;
  }

  #linkedin {
    box-shadow: inset 0 -8px 0 #0a66c280;
  }

  p {
    line-height: 1.6;
  }
`;

const Contact = () => {
  const [contactState, setContactState] = React.useState({});

  const handleOnChange = (e) => {
    setContactState({
      ...contactState,
      [e.target.name]: e.target.value
    });
  }

  const handleSendMessage = async () => {
    if (contactState.email && contactState.message) {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contactEmail: contactState.email,
          message: contactState.message
        })
      }
      const response = await fetch('/.netlify/functions/send-contact-email', options);
      console.log("Response", response);

    } else {
      alert('Please leave your email or message');
    }
  }

  return (
    <StyledContact>
      <div className="contact">
        <Grid container className="contactContainer">
          <h3>Got an interesting project ideas? </h3>
          <p>
            Best way to reach me is shoot me an email at <a id="email" href="mailto:bjargal56@gmail.com">bjargal56@gmail.com</a>
            <br />
            or you can connect me on <a id="linkedin" href="https://linkedin.com/in/bileg-jargal" target="_blank" rel="noreferrer">Linkedin</a>
          </p>
          <form className="leave-message" onChange={handleOnChange} onSubmit={handleSendMessage}>
            <input name="email" className="email" type="text" placeholder="Type your email here. So I can respond your message"></input>
            <textarea name="message" className="message" rows="6" type="textarea" placeholder="Leave your message :)"></textarea>
            <button className="submit">Submit message</button>
          </form>
        </Grid>
      </div>
    </StyledContact>
  )
};

export default Contact;