import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components'
import Header from './components/Header';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

const Global = createGlobalStyle`
  html {
    font-size: 62.5%;
    body {
      font-size: 1.6rem;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    margin: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
  }
`
function App() {
  return (
    <Router>
      <Global />
      <Helmet>
        <meta charSet="utf-8" />
        <title>bilegjargal.me</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap" rel="stylesheet" />

      </Helmet>
      <Route path="/:page" component={Header} />
      <Route exact path='/' component={Header} />
      <Switch>
        <Route exact path='/' component={About} />
        <Route exact path='/about' component={About} />
        <Route exact path='/contact' component={Contact} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
